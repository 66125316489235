"use strict";
/**
 * Initializes the Translations
 * @param receivedOptions
 * @return {*}
 */


function requestWithXmlHttpRequest (options, url, payload, callback) {
    if (payload && typeof payload === 'object') {
        // if (!cache) payload._t = Date.now()
        // URL encoded form payload must be in querystring format
        payload = addQueryString('', payload).slice(1)
    }

    try {
        let x
        x = new XMLHttpRequest();
        x.open('GET', url, 1)
        if (!options.crossDomain) {
            x.setRequestHeader('X-Requested-With', 'XMLHttpRequest')
        }
        x.withCredentials = !!options.withCredentials
        if (payload) {
            x.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded')
        }
        if (x.overrideMimeType) {
            x.overrideMimeType('application/json')
        }
        let h = options.customHeaders
        h = typeof h === 'function' ? h() : h
        if (h) {
            for (var i in h) {
                x.setRequestHeader(i, h[i])
            }
        }
        x.onreadystatechange = () => {
            x.readyState > 3 && callback(x.status >= 400 ? x.statusText : null, { status: x.status || 200, data: x.responseText }) // in android webview loading a file is status status 0
        }
        x.send(payload)
    } catch (e) {
        console && console.log(e)
    }
}


window.initTranslations = function initTranslations(options = {}) {

    options.backend = {
        loadPath: "resources/translations/js/{{lng}}.json",
        request: requestWithXmlHttpRequest //otherwise uses fetch-api that cannot load from file:// scheme.
    };

    return i18n.use(i18nextHttpBackend).init(options);
};

var _filteredKey = function _filteredKey(inputKey) {
    let keyParts = inputKey.split(".");
    return String(keyParts[keyParts.length - 1]);
}; // end


window._ = function _(key, config = {}) {
    config.interpolation = {
        prefix: "__",
        suffix: "__",
        escapeValue: false
    };
    // Required for some nested objects!
    config.returnObjects = true;
    let result = i18n.t(key, config);

    if (!(result instanceof String || typeof result === "string")) {
        if (result instanceof Object) {
            // nested value (dateTime.hour.hour or alike)
            result = result[key.split(".").pop()];

            // check if resolving did succeed
            if (!(result instanceof String || typeof result === "string")) {
                // resolving the issue did not succeed.
                result = _filteredKey(key);
            }
        } else {
            // not found, return the key!
            result = _filteredKey(key);
        }
    }

    return result;
};
